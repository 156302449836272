@use '../../global/' as *;

.p-form {
  &--wrap {
    padding-bottom: 40px;
    @include responsive(md) {
      padding-bottom: 80px;
    }
  }
  &--radio {
    label {
      display: block;
      margin-bottom: 20px;
    }
  }
  &--status {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 25px;
    > li {
      flex-grow: 1;
      background: #E3E3E3;
      font-weight: bold;
      text-align: center;
      padding: 10px 15px;
      position: relative;
      &.is-active {
        background: #0C161F;
        color: #fff;
      }
      &:not(:last-child) {
        &:after {
          content: "";
          height: 29px;
          width: 10px;
          position: absolute;
          right: -17px;
          bottom: 0;
          top: 0;
          margin: auto;
          background: url(../images/common/ico-arrow-flow.svg) no-repeat;
          background-size: contain;
        }
      }
    }
  }
  &--title {
    font-size: 1.8rem;
    font-weight: bold;
    border-bottom: 1px solid #C7C7C7;
    padding-bottom: 20px;
    margin-top: 60px;
    @include responsive(md) {
      font-size: 2.4rem;
      margin-top: 90px;
    }
  }
  &--wrap {
    padding: 0 15px;
    margin-bottom: 40px;
  }
  &--label {
    font-weight: bold;
  }
  &--textarea {
    width: 100%;
    min-height: 200px;
    resize: vertical;
    border: 1px solid #DDDDDD;
    &:placeholder-shown {
      background: #FDE8E8;
    }
  }
  &--text {
    width: 100%;
    max-width: 445px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #DDDDDD;
    display: block;
    margin-top: 7px;
    &:placeholder-shown {
      background: #FDE8E8;
    }
    &.small {
      max-width: 345px;
    }
  }
  &--agreement {
    border: 1px solid #08141D;
    text-align: center;
    &__head {
      color: #fff;
      background: #09141D;
      font-size: 1.8rem;
      margin: 0;
      padding: 20px;
      text-align: center;
      font-weight: normal;
      @include responsive(md) {
        font-size: 2.4rem;
      }
    }
    &__content {
      padding: 20px;
      text-align: left;
    }
    &__check {
      text-align: center;
      margin: 20px 0;
    }
    &__submit {
      text-align: center;
      margin: 40px 0 20px;
    }
  }
}
