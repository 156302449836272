@use '../global/' as *;

.l-container {
  @include container;

  &.small {
    max-width: 920px;
  }
  &.privacy {
    margin-bottom: 100px;
    p {
      line-height: 1.9em;
    }
  }
  &--pagetop {
    background: url(../images/common/bg-pagetop.jpg) no-repeat;
    background-size: contain;
    background-position: center top;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.8rem;
    font-weight: normal;
    padding: 110px 15px 0;
    margin-bottom: 0;
    @include responsive(md) {
      height: 350px;
      padding-top: 80px;
      font-size: 4.3rem;
    }
    &.about {
      background-image: url(../images/about/bg-pagetop-sp.jpg);
      background-size: cover;
      @include responsive(md) {
        background-image: url(../images/about/bg-pagetop.jpg);
        background-size: 100% 80%;
      }
    }
    &.guide {
      background-image: url(../images/guide/bg-pagetop.jpg);
      background-size: cover;
      @include responsive(md) {
        background-image: url(../images/guide/bg-pagetop.jpg);
        background-size: 100% 80%;
      }
    }
  }

  &--gray {
    background: #222;
  }
  &--black {
    position: relative;
    background: #0B0B0B;
  }

  &--breadcrumb {
    border-top: 1px solid #C7C7C7;
    position: relative;
    &__inner {
      padding: 20px 0 58px;
      @include container;
      @include responsive(md) {
        display: flex;
        padding-top: 17px;
        padding-bottom: 17px;
      }
    }
    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      color: #6A6A6A;
      a {
        color: #000;
        text-decoration: none;
        &:after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 11px;
          background: url(../images/common/ico-bread-layer.svg) no-repeat;
          background-size: contain;
          margin-left: 12px;
        }
        img {
          margin-right: 4px;
        }
      }
    }
    &__button {
      min-width: 154px;
      height: 48px;
      margin-left: auto;
      background: #08141D;
      color: #fff;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      &:after {
        content: "";
        height: 24px;
        width: 24px;
        background: url(../images/common/ico-pagetop.svg) no-repeat;
        background-size: contain;
        margin-left: 12px;
      }
      @include responsive(md) {
        height: 100%;
      }
    }
  }
}
