.u-animation {
  &--slidein {
    transform: translate(0, 30px);
    opacity: 0;
    transition: 1s;
    transition-property: transform, opacity;
    &.is-show {
      transform: translate(0);
      opacity: 1;
    }
  }
}
