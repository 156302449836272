
:root{
  //---------カラー設定---------
  --base-color: #eee;
  --main-color: #000;
  --sub-color: #000;
  --accent-color: #000;
  --text-color: #333;
  --link-color: #04c;
  --white-color: #FFF;
  //---------フォント設定---------
  --base-font-size: 1.6rem;
  --base-font-weight: 400;
  --base-line-height: 1.625;
  //---------フォントファミリー設定---------
  --base-font-family: "Hiragino Sans","Meiryo","Hiragino Kaku Gothic ProN","Arial",sans-serif;
  --serif-font-family: "Shippori Mincho", serif;;
  //--------z-index設定--------
  --z-modal: 1000;
  --z-overlay: 100;
  --z-header: 20;
  //--------container設定--------
  --container-padding: 15px;
  --container-size: 1100px;
  --container-width: calc(var(--container-size) + (var(--container-padding)*2));
  //--------animation設定--------
  --animation-normal: all 0.2s ease-in;
  //--------animation設定--------
  --path--image: './images/';
  --path--image-icon: var(--path--image)'icon/';
}

html {
  font-size: 62.5%;
}

body{
  font-family: var(--serif-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  line-height: var(--base-line-height);
  scroll-behavior: smooth;
  margin: 0;
  margin-top: 60px;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-smooth: always;
  letter-spacing: 0.04em;
  color: #000;
}

img {
  max-width: 100%;
}
a {
  color: #000;
  &:hover {
    opacity: .6;
  }
}
