.u-m {
  &b-10 {
    margin-bottom: 10px;
  }

  &b-60 {
    margin-bottom: 60px;
  }

  &t-40 {
    margin-top: 40px;
  }
}
