@use '../../global/' as *;

.p-table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  margin: 60px 0 0;
  tr {
    &:first-of-type {
      td, th {
        border-top: 1px solid #C7C7C7;
      }
    }
  }
  td, th {
    border-bottom: 1px solid #C7C7C7;
    padding: 20px;
    text-align: left;
    &:first-child {
      vertical-align: top;
      white-space: nowrap;
    }
  }
  &.end-right {
    tr {
      th, td {
        &:first-child {
          white-space: wrap;
        }
        &:last-child {
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }
}
