@charset "UTF-8";
:root {
  --base-color: #eee;
  --main-color: #000;
  --sub-color: #000;
  --accent-color: #000;
  --text-color: #333;
  --link-color: #04c;
  --white-color: #FFF;
  --base-font-size: 1.6rem;
  --base-font-weight: 400;
  --base-line-height: 1.625;
  --base-font-family: "Hiragino Sans","Meiryo","Hiragino Kaku Gothic ProN","Arial",sans-serif;
  --serif-font-family: "Shippori Mincho", serif;
  --z-modal: 1000;
  --z-overlay: 100;
  --z-header: 20;
  --container-padding: 15px;
  --container-size: 1100px;
  --container-width: calc(var(--container-size) + (var(--container-padding)*2));
  --animation-normal: all 0.2s ease-in;
  --path--image: "./images/";
  --path--image-icon: var(--path--image)"icon/";
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--serif-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  line-height: var(--base-line-height);
  scroll-behavior: smooth;
  margin: 0;
  margin-top: 60px;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-smooth: always;
  letter-spacing: 0.04em;
  color: #000;
}

img {
  max-width: 100%;
}

a {
  color: #000;
}
a:hover {
  opacity: 0.6;
}

/* Document
 * ========================================================================== */
/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Backgrounds do not repeat by default (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}

/**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritance in all browsers (opinionated).
  */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
  * 1. Use the default cursor in all browsers (opinionated).
  * 2. Change the line height in all browsers (opinionated).
  * 3. Breaks words to prevent overflow in all browsers (opinionated).
  * 4. Use a 4-space tab width in all browsers (opinionated).
  * 5. Remove the grey highlight on links in iOS (opinionated).
  * 6. Prevent adjustments of font size after orientation changes in iOS.
  */
:where(:root) {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  overflow-wrap: break-word; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -webkit-tap-highlight-color: transparent; /* 5 */
  -webkit-text-size-adjust: 100%; /* 6 */
  text-size-adjust: 100%; /* 6 */
}

/* Sections
  * ========================================================================== */
/**
  * Remove the margin in all browsers (opinionated).
  */
:where(body) {
  margin: 0;
}

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Edge, Firefox, and Safari.
  */
:where(h1) {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
  * ========================================================================== */
/**
  * Remove the margin on nested lists in Chrome, Edge, and Safari.
  */
:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

/**
  * 1. Correct the inheritance of border color in Firefox.
  * 2. Add the correct box sizing in Firefox.
  */
:where(hr) {
  color: inherit; /* 1 */
  height: 0; /* 2 */
}

/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */
:where(nav) :where(ol, ul) {
  list-style-type: none;
  padding: 0;
}

/**
  * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
  */
:where(nav li)::before {
  content: "​";
  float: left;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  * 3. Prevent overflow of the container in all browsers (opinionated).
  */
:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
}

/* Text-level semantics
  * ========================================================================== */
/**
  * Add the correct text decoration in Safari.
  */
:where(abbr[title]) {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
:where(b, strong) {
  font-weight: bolder;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
  * Add the correct font size in all browsers.
  */
:where(small) {
  font-size: 80%;
}

/* Embedded content
  * ========================================================================== */
/*
  * Change the alignment on media elements in all browsers (opinionated).
  */
:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

/**
  * Remove the border on iframes in all browsers (opinionated).
  */
:where(iframe) {
  border-style: none;
}

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */
:where(svg:not([fill])) {
  fill: currentColor;
}

/* Tabular data
  * ========================================================================== */
/**
  * 1. Collapse border spacing in all browsers (opinionated).
  * 2. Correct table border color in Chrome, Edge, and Safari.
  * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
  */
:where(table) {
  border-collapse: collapse; /* 1 */
  border-color: currentColor; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
  * ========================================================================== */
/**
  * Remove the margin on controls in Safari.
  */
:where(button, input, select) {
  margin: 0;
}

/**
  * Correct the inability to style buttons in iOS and Safari.
  */
:where(button, [type=button i], [type=reset i], [type=submit i]) {
  -webkit-appearance: button;
}

/**
  * Change the inconsistent appearance in all browsers (opinionated).
  */
:where(fieldset) {
  border: 1px solid #a0a0a0;
}

/**
  * Add the correct vertical alignment in Chrome, Edge, and Firefox.
  */
:where(progress) {
  vertical-align: baseline;
}

/**
  * 1. Remove the margin in Firefox and Safari.
  * 3. Change the resize direction in all browsers (opinionated).
  */
:where(textarea) {
  margin: 0; /* 1 */
  resize: vertical; /* 3 */
}

/**
  * 1. Correct the odd appearance in Chrome, Edge, and Safari.
  * 2. Correct the outline style in Safari.
  */
:where([type=search i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
  * Correct the cursor style of increment and decrement buttons in Safari.
  */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
  * Correct the text style of placeholders in Chrome, Edge, and Safari.
  */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
  * Remove the inner padding in Chrome, Edge, and Safari on macOS.
  */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style upload buttons in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
  * ========================================================================== */
/*
  * Add the correct styles in Safari.
  */
:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/*
  * Add the correct display in Safari.
  */
:where(details > summary:first-of-type) {
  display: list-item;
}

/* Accessibility
  * ========================================================================== */
/**
  * Change the cursor on busy elements in all browsers (opinionated).
  */
:where([aria-busy=true i]) {
  cursor: progress;
}

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements in all browsers (opinionated).
  */
:where([aria-disabled=true i], [disabled]) {
  cursor: not-allowed;
}

/*
  * Change the display on visually hidden accessible elements
  * in all browsers (opinionated).
  */
:where([aria-hidden=false i][hidden]) {
  display: initial;
}

:where([aria-hidden=false i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/*
実装例
@include responsive(md) {
  font-size: 14px;
}
*/
/*
実装例
@include icon(30px,30px,mainbg.jpg)
*/
/*
実装例
@include hover{
  内容
}
*/
.l-about--top {
  background: #0B0B0B;
  color: #fff;
  position: relative;
  padding: 10px 0;
}
.l-about--top:before {
  content: "";
  height: 60px;
  width: 100%;
  display: block;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.l-about--top > * {
  z-index: 10;
}
.l-about--top__img01 {
  position: relative;
  display: block;
  max-width: 545px;
  width: 77%;
  margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .l-about--top__img01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 35%;
  }
}
.l-about--top__img02 {
  position: relative;
  display: block;
  max-width: 235px;
  width: 77%;
  margin: 68px 0 0 auto;
}
@media screen and (min-width: 768px) {
  .l-about--top__img02 {
    position: absolute;
    top: 25%;
    right: 0;
    width: 15%;
  }
}
.l-about--top__concept {
  position: relative;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  background: url(../images/index/bg_logo.png) no-repeat;
  background-position: left 20px center;
  background-size: auto 80%;
}
@media screen and (min-width: 768px) {
  .l-about--top__concept {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-about--top__concept {
    max-width: 540px;
    width: 50%;
    margin-top: 170px;
    padding-left: 75px;
    margin-left: 40%;
    margin-bottom: 180px;
    z-index: 15;
  }
}
.l-about--top__concept__title {
  font-size: 2.4rem;
  font-weight: normal;
  margin-bottom: 44px;
}
@media screen and (min-width: 768px) {
  .l-about--top__concept__title {
    font-size: 2.8rem;
    margin-bottom: 60px;
  }
}
.l-about--top__guide {
  position: relative;
  margin-top: -40px;
  padding-top: 100px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .l-about--top__guide {
    margin-top: -140px;
    padding-top: 200px;
  }
}
.l-about--top__guide__inner {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-about--top__guide__inner {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-about--top__guide__content {
    width: 40%;
  }
}
.l-about--top__guide__map {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
}
@media screen and (min-width: 768px) {
  .l-about--top__guide__map {
    position: absolute;
    right: 1%;
    top: 200px;
    margin: auto 0;
    width: 50%;
    max-width: 680px;
  }
}
.l-about--top__guide__list {
  padding-left: 20px;
}
.l-about--top__guide__list li::marker {
  font-size: 0.5em;
}
.l-about--top__guide__list li a {
  color: #fff;
  position: relative;
}
.l-about--top__guide__list li a:hover {
  opacity: 0.6;
}
.l-about--top__guide__list li a:after {
  content: "";
  display: inline-block;
  position: relative;
  height: 10px;
  width: 10px;
  border: 1px solid #fff;
  border-right: none;
  border-top: none;
  margin-left: 10px;
  transform: rotate(-45deg);
  top: -0.2em;
}
.l-about--content__head {
  position: relative;
  padding-bottom: 70px;
}
@media screen and (min-width: 768px) {
  .l-about--content__head {
    padding-bottom: 54px;
  }
}
.l-about--content__head__img {
  width: 100%;
  z-index: 5;
}
.l-about--content__head__word {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin: 0;
  bottom: -70px;
}
@media screen and (min-width: 768px) {
  .l-about--content__head__word {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-about--content__head__word {
    bottom: -54px;
  }
}
.l-about--content__head__word > span {
  background: #08141D;
  font-size: 2.4rem;
  color: #fff;
  font-weight: normal;
  display: inline-block;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  padding: 20px 20px 30px 15px;
  letter-spacing: 0.2em;
}
@media screen and (min-width: 768px) {
  .l-about--content__head__word > span {
    font-size: 3.2rem;
    padding: 16px 77px 30px 77px;
  }
}
.l-about--content__content {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-about--content__content {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-about--content__content {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin: 60px auto;
  }
}
@media screen and (min-width: 768px) {
  .l-about--content__content.pc-r .l-about--content__content__word {
    order: 2;
  }
  .l-about--content__content.pc-r .l-about--content__content__img {
    order: 1;
  }
}
.l-about--content__content__word {
  margin: 40px 0;
}
@media screen and (min-width: 768px) {
  .l-about--content__content__word {
    width: 40%;
  }
}
.l-about--content__content__img {
  margin: 40px 0;
}
@media screen and (min-width: 768px) {
  .l-about--content__content__img {
    width: 48%;
  }
}
.l-about--content__content__caption {
  font-size: 1.4rem;
}

.l-container {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-container {
    width: 100%;
    max-width: var(--container-width);
  }
}
.l-container.small {
  max-width: 920px;
}
.l-container.privacy {
  margin-bottom: 100px;
}
.l-container.privacy p {
  line-height: 1.9em;
}
.l-container--pagetop {
  background: url(../images/common/bg-pagetop.jpg) no-repeat;
  background-size: contain;
  background-position: center top;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  font-weight: normal;
  padding: 110px 15px 0;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .l-container--pagetop {
    height: 350px;
    padding-top: 80px;
    font-size: 4.3rem;
  }
}
.l-container--pagetop.about {
  background-image: url(../images/about/bg-pagetop-sp.jpg);
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .l-container--pagetop.about {
    background-image: url(../images/about/bg-pagetop.jpg);
    background-size: 100% 80%;
  }
}
.l-container--pagetop.guide {
  background-image: url(../images/guide/bg-pagetop.jpg);
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .l-container--pagetop.guide {
    background-image: url(../images/guide/bg-pagetop.jpg);
    background-size: 100% 80%;
  }
}
.l-container--gray {
  background: #222;
}
.l-container--black {
  position: relative;
  background: #0B0B0B;
}
.l-container--breadcrumb {
  border-top: 1px solid #C7C7C7;
  position: relative;
}
.l-container--breadcrumb__inner {
  padding: 20px 0 58px;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-container--breadcrumb__inner {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-container--breadcrumb__inner {
    display: flex;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
.l-container--breadcrumb__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  color: #6A6A6A;
}
.l-container--breadcrumb__list a {
  color: #000;
  text-decoration: none;
}
.l-container--breadcrumb__list a:after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 11px;
  background: url(../images/common/ico-bread-layer.svg) no-repeat;
  background-size: contain;
  margin-left: 12px;
}
.l-container--breadcrumb__list a img {
  margin-right: 4px;
}
.l-container--breadcrumb__button {
  min-width: 154px;
  height: 48px;
  margin-left: auto;
  background: #08141D;
  color: #fff;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.l-container--breadcrumb__button:after {
  content: "";
  height: 24px;
  width: 24px;
  background: url(../images/common/ico-pagetop.svg) no-repeat;
  background-size: contain;
  margin-left: 12px;
}
@media screen and (min-width: 768px) {
  .l-container--breadcrumb__button {
    height: 100%;
  }
}

.l-footer__reserve {
  padding: 20px 15px;
  background: url(../images/index/bg_g_02.jpg) no-repeat #666;
  background-size: cover;
  background-position: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .l-footer__reserve {
    padding: 60px;
  }
}
.l-footer__reserve:after {
  content: "";
  width: 100%;
  height: 40%;
  background: #262626;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
}
.l-footer__reserve__inner {
  position: relative;
  background: #fff;
  padding: 60px;
  z-index: 10;
  text-align: center;
  letter-spacing: 0.04em;
}
.l-footer__reserve__title {
  font-size: 2.4rem;
  font-weight: normal;
}
@media screen and (min-width: 768px) {
  .l-footer__reserve__title {
    font-size: 3.2rem;
  }
}
.l-footer__reserve__tel {
  font-size: 2.8rem;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .l-footer__reserve__tel {
    font-size: 4.9rem;
  }
}
.l-footer__reserve__tel > img {
  width: 0.8em;
  height: auto;
  margin-right: 0.2em;
}
.l-footer__content {
  background: #262626;
  padding: 20px 0 7px;
}
@media screen and (min-width: 768px) {
  .l-footer__content {
    padding: 40px 0 45px;
  }
}
.l-footer__content__top {
  border-bottom: 1px solid #C7C7C7;
  padding: 0 15px 40px;
}
@media screen and (min-width: 768px) {
  .l-footer__content__top {
    padding-bottom: 80px;
  }
}
.l-footer__content__top__sns, .l-footer__content__top__list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}
.l-footer__content__top__sns a, .l-footer__content__top__list a {
  color: #fff;
  text-decoration: none;
}
.l-footer__content__top__sns {
  margin-bottom: 25px;
  gap: 30px;
}
.l-footer__content__top__list {
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .l-footer__content__top__list {
    flex-direction: row;
    gap: 30px;
  }
}
.l-footer__content__bottom {
  padding: 25px 15px 0;
}
@media screen and (min-width: 768px) {
  .l-footer__content__bottom {
    padding-top: 40px;
  }
}
.l-footer__content__bottom__list {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  font-size: 1.4rem;
}
.l-footer__content__bottom__list a {
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .l-footer__content__bottom__list {
    justify-content: flex-end;
    gap: 30px;
  }
}
.l-footer__content__bottom__bottom {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .l-footer__content__bottom__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.l-footer__content__bottom__logo {
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
}
.l-footer__content__bottom__logo img {
  margin-right: 12px;
}
@media screen and (min-width: 768px) {
  .l-footer__content__bottom__logo {
    display: flex;
    align-items: center;
  }
}
.l-footer__content__bottom__logo__link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;
}
@media screen and (min-width: 768px) {
  .l-footer__content__bottom__logo__link {
    margin-left: 20px;
  }
}
.l-footer__content__bottom__logo__link > img {
  height: 9px;
  width: 9px;
  margin: 0 0 0 5.5px;
}
.l-footer__content__bottom__small {
  display: block;
  font-size: 1.2rem;
  text-align: right;
  margin-top: 26px;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .l-footer__content__bottom__small {
    margin-top: 0;
  }
}

.l-guide--time {
  padding: 60px 0;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  margin-top: 60px;
}
@media screen and (min-width: 768px) {
  .l-guide--time {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-guide--time {
    padding: 93px 15px 100px;
    margin-top: 80px;
    max-width: 920px;
  }
}
.l-guide--time > * {
  text-align: center;
}
.l-guide--time > section {
  margin-bottom: 60px;
}
.l-guide--time__title {
  font-size: 2.4rem;
  letter-spacing: 0.2em;
  margin: 0 auto 50px;
  font-weight: normal;
}
@media screen and (min-width: 768px) {
  .l-guide--time__title {
    font-size: 3.2rem;
    letter-spacing: 0.04em;
  }
}
@media screen and (min-width: 768px) {
  .l-guide--time__title.small {
    font-size: 2.4rem;
  }
}
.l-guide--time__info {
  text-align: center;
}
.l-guide--time__info__title {
  background: #09141D;
  font-size: 1.8rem;
  padding: 25px;
}
@media screen and (min-width: 768px) {
  .l-guide--time__info__title {
    font-size: 2.4rem;
  }
}
.l-guide--time__info__content {
  background: #fff;
  color: #09141D;
  font-size: 3.2rem;
  padding: 25px;
}
@media screen and (min-width: 768px) {
  .l-guide--time__info__content {
    font-size: 3.8rem;
  }
}
.l-guide--time__info__content span {
  font-size: 2.2rem;
}
@media screen and (min-width: 768px) {
  .l-guide--time__info__content span {
    font-size: 2.4rem;
  }
}
.l-guide--time__info__content__inner {
  display: inline-block;
  text-align: left;
}
.l-guide--time__info__content__small {
  display: block;
  font-size: 1.8rem;
}
@media screen and (min-width: 768px) {
  .l-guide--time__info__content__small {
    font-size: 2rem;
  }
}
.l-guide--time__info__content__small2 {
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .l-guide--time__info__content__small2 {
    font-size: 2.8rem;
  }
}
.l-guide--time__info__content p {
  font-size: var(--base-font-size);
  text-align: right;
}
.l-guide--access {
  color: #fff;
  padding: 60px 0 40px;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-guide--access {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-guide--access {
    min-height: 548px;
    max-width: 920px;
  }
}
@media screen and (min-width: 768px) {
  .l-guide--access__wrap {
    max-width: 445px;
    width: 40%;
  }
}
.l-guide--access__title {
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) {
  .l-guide--access__title {
    font-size: 3.2rem;
  }
}
.l-guide--access--map {
  width: 100%;
  height: 321px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
@media screen and (min-width: 768px) {
  .l-guide--access--map {
    position: absolute;
    height: 100%;
    width: 57%;
    max-width: 640px;
    right: 0;
    top: 0;
  }
}
.l-guide--flow {
  padding: 60px 0 50px;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-guide--flow {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-guide--flow {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.l-guide--flow__inner {
  margin: 32px auto 0;
}
@media screen and (min-width: 768px) {
  .l-guide--flow__inner {
    max-width: 920px;
    margin-top: 60px;
  }
}
.l-guide--flow__content {
  margin-bottom: 48px;
}
@media screen and (min-width: 768px) {
  .l-guide--flow__content {
    display: flex;
    margin-bottom: 60px;
  }
}
.l-guide--flow__content:last-of-type {
  margin-bottom: 0;
}
.l-guide--flow__content__head {
  text-align: center;
  background: #09141D;
  color: #fff;
  font-size: 2rem;
  padding: 40px;
}
@media screen and (min-width: 768px) {
  .l-guide--flow__content__head {
    width: 350px;
    flex-shrink: 0;
    font-size: 2.4rem;
    margin-right: 30px;
  }
}
.l-guide--flow__content__head > * {
  display: block;
  margin: 0 auto;
}
.l-guide--flow__content__head > img {
  margin-bottom: 20px;
}
.l-guide--flow__content__head > span {
  font-size: 1.6rem;
}
@media screen and (min-width: 768px) {
  .l-guide--flow__content__head > span {
    font-size: 1.8rem;
  }
}
.l-guide--flow__content__content > p {
  line-height: 2em;
  margin: 0;
}

.l-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  background: #fff;
}
.l-header__logo {
  margin: 0;
  padding: 0;
  position: relative;
  align-self: flex-start;
  width: 98px;
  height: 120px;
}
.l-header__logo > img {
  width: 98px;
  height: 120px;
  display: block;
  position: absolute;
}
.l-header__logo a:hover {
  opacity: 1;
}
.l-header__nav {
  align-self: center;
  margin-right: 12px;
  line-height: 1.2;
  display: none;
}
@media screen and (min-width: 768px) {
  .l-header__nav {
    display: block;
  }
}
.l-header__nav > ul {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style-type: none;
}
.l-header__nav > ul li {
  position: relative;
}
.l-header__nav > ul li:not([class]) {
  padding-right: 20px;
}
.l-header__nav > ul li:not([class]):after {
  content: "";
  width: 1px;
  height: 1.3em;
  background: #C7C7C7;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.l-header__nav > ul li:not([class]):has(+ .l-header__nav__reserve) {
  padding-right: 0;
}
.l-header__nav > ul li:not([class]):has(+ .l-header__nav__reserve):after {
  display: none;
}
.l-header__nav > ul li.l-header__nav__reserve > a {
  padding: 12px 17px;
  background: #09141D;
  color: #fff;
  border: 1px solid #09141D;
  transition: 0.2s;
}
.l-header__nav > ul li.l-header__nav__reserve > a:hover {
  background: #fff;
  color: #09141D;
  opacity: 1;
}
.l-header__nav a {
  color: #000;
  text-decoration: none;
}
.l-header__nav a:hover {
  opacity: 0.6;
}
.l-header__toggle {
  display: block;
  border: none;
  background: transparent;
  margin-right: 17px;
}
.l-header__toggle > img {
  width: 30px;
}
@media screen and (min-width: 768px) {
  .l-header__toggle {
    display: none;
  }
}
.l-header__toggle__close {
  display: none;
}
.l-header__toggle.is-active .l-header__toggle__open {
  display: none;
}
.l-header__toggle.is-active .l-header__toggle__close {
  display: block;
}
.l-header__top {
  margin: 0;
  position: absolute;
  top: 0;
  left: -1px;
  z-index: 4010;
}
.l-header__top img {
  width: 155px;
}
.l-header__top a:hover {
  opacity: 1;
}
.l-header__navsp {
  overflow-y: scroll;
  position: fixed;
  height: calc(100% - 60px);
  width: 100%;
  z-index: 3990;
  background: url(../images/common/bg-menu.jpg) no-repeat;
  background-size: 100% 100%;
  padding: 158px 15px 40px;
  color: #fff;
  display: none;
}
@media screen and (min-width: 768px) {
  .l-header__navsp {
    display: none !important;
  }
}
.l-header__navsp a {
  color: #fff;
  text-decoration: none;
}
.l-header__navsp a:hover {
  opacity: 0.6;
}
.l-header__navsp__list {
  list-style-type: none;
  margin: 0 0 24px;
  padding: 0;
  color: #fff;
  font-size: 2.4rem;
}
.l-header__navsp__list > li:not(:last-of-type) {
  margin-bottom: 30px;
}
.l-header__navsp__button {
  display: block;
  text-align: center;
  background: #09141D;
  padding: 20px;
  font-size: 2rem;
  margin-top: 30px;
}
.l-header__navsp__links {
  margin-top: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.l-index--loading {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #0B0B0B;
  z-index: 5000;
  animation: loading 0.5s both 2.5s ease-in;
}
@keyframes loading {
  0% {
    opacity: 1;
  }
  99% {
    z-index: 5000;
  }
  100% {
    opacity: 0;
    z-index: -5000;
  }
}
.l-index--loading > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: loadimg 1s both ease;
}
@keyframes loadimg {
  from {
    filter: blur(10px);
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  to {
    filter: blur(0);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
.l-index--top {
  width: 100%;
  height: calc(100vh - 60px);
  background: #333;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  .l-index--top {
    display: flex;
  }
}
.l-index--top__cc {
  position: absolute;
  top: 50%;
  left: 18px;
  z-index: 10;
  transform: translateY(-50%);
  color: #fff;
  animation: blur 2s both 2s ease-in;
  letter-spacing: 0.2em;
}
@keyframes blur {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0);
  }
}
@media screen and (min-width: 768px) {
  .l-index--top__cc {
    top: auto;
    left: 140px;
    bottom: 70px;
    transform: translateY(0);
  }
}
.l-index--top__cc__box {
  display: inline-block;
  font-size: 5vw;
  font-size: clamp(14px, 5vw, 24px);
  letter-spacing: 0.1em;
  padding: 12px;
  border: 1px solid #fff;
  margin-bottom: 17px;
}
@media screen and (min-width: 768px) {
  .l-index--top__cc__box {
    font-size: 2.8rem;
  }
}
.l-index--top__cc__text {
  margin: 0;
  font-size: 3.2rem;
  font-weight: normal;
  letter-spacing: 0.12em;
}
@media screen and (min-width: 768px) {
  .l-index--top__cc__text {
    font-size: 4.8rem;
  }
}
.l-index--top__instagram {
  position: absolute;
  right: 0;
  bottom: 35px;
  z-index: 5;
}
@media screen and (min-width: 768px) {
  .l-index--top__instagram {
    right: 35px;
  }
}
.l-index--top__instagram > img {
  display: block;
  height: 119px;
  width: 119px;
}
@media screen and (min-width: 768px) {
  .l-index--top__instagram > img {
    height: 142px;
    width: 143px;
  }
}
.l-index--top__image {
  width: 100%;
  height: 50%;
  position: relative;
  z-index: 5;
}
@media screen and (min-width: 768px) {
  .l-index--top__image {
    width: 50%;
    height: 100%;
  }
}
.l-index--top__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(0);
  animation: slideshow 20s ease infinite;
}
.l-index--top__image picture:nth-of-type(2) img {
  animation-delay: 10s;
  opacity: 0;
}
.l-index--top__image.right img {
  animation-delay: 0.1s;
}
.l-index--top__image.right picture:nth-of-type(2) img {
  animation-delay: 10.1s;
}
@keyframes slideshow {
  0% {
    transform: translateY(100%);
    opacity: 1;
    z-index: 1;
  }
  3% {
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  51% {
    z-index: 0;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.l-index--top__bottom {
  text-align: center;
  padding: 45px 15px;
}
@media screen and (min-width: 768px) {
  .l-index--top__bottom {
    padding: 88px 15px 95px;
  }
}
.l-index--top__bottom > h2 {
  margin: 0;
  font-size: 1.6rem;
  color: #6A6A6A;
}
@media screen and (min-width: 768px) {
  .l-index--top__bottom > h2 {
    font-size: 2rem;
  }
}
.l-index--top__scroll {
  position: absolute;
  left: 10px;
  bottom: 20px;
  z-index: 10;
  color: #fff;
  transform: rotate(90deg);
}
.l-index--top__scroll::after {
  content: "";
  /*描画位置*/
  position: absolute;
  top: -10px;
  right: 50px;
  display: block;
  /*線の形状*/
  height: 1px;
  width: 40px;
  transform: rotate(180deg);
  background: #fff;
  /*線の動き1.4秒かけて動く。永遠にループ*/
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}
@keyframes pathmove {
  0% {
    width: 0;
    left: -50px;
    opacity: 0;
  }
  30% {
    width: 60px;
    opacity: 1;
  }
  100% {
    width: 0;
    left: 50px;
    opacity: 0;
  }
}
.l-index--concept {
  background: url(../images/index/bg_g.jpg) no-repeat #444;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.l-index--concept p {
  line-height: 1.925;
}
.l-index--concept.black {
  background: #0B0B0B;
}
@media screen and (min-width: 768px) {
  .l-index--concept.black .l-index--concept__content {
    justify-content: flex-start;
  }
}
.l-index--concept.black .l-index--concept__word {
  order: 2;
  flex-grow: 1;
  letter-spacing: 0.04em;
  position: relative;
}
.l-index--concept.black .l-index--concept__word:after {
  content: "";
  width: 94px;
  height: 228px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(../images/index/bg_logo.png) no-repeat;
  background-size: contain;
}
@media screen and (min-width: 768px) {
  .l-index--concept.black .l-index--concept__word:after {
    width: 152px;
    height: 370px;
  }
}
.l-index--concept.black .l-index--concept__image {
  order: 1;
}
.l-index--concept__content {
  padding: 90px 0 80px;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media screen and (min-width: 768px) {
  .l-index--concept__content {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-index--concept__content {
    display: flex;
    justify-content: space-between;
  }
}
.l-index--concept__word {
  margin-bottom: 47px;
}
@media screen and (min-width: 768px) {
  .l-index--concept__word {
    margin-left: 95px;
  }
}
@media screen and (min-width: 768px) {
  .l-index--concept__word > p {
    margin-bottom: 48px;
  }
}
.l-index--concept__word > img {
  display: block;
  width: 100px;
  margin: 0 auto 60px;
}
@media screen and (min-width: 768px) {
  .l-index--concept__word > img {
    width: 159px;
    margin-bottom: 72px;
  }
}
.l-index--facility {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .l-index--facility {
    margin-top: 100px;
  }
}
.l-index--facility__info {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-index--facility__info {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-index--facility__info {
    display: flex;
  }
  .l-index--facility__info > * {
    width: 50%;
  }
}
.l-index--facility__info__word {
  margin-bottom: 40px;
}
.l-index--guide {
  background: url(../images/index/bg_wg.jpg) no-repeat #444;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 112px;
}
@media screen and (min-width: 768px) {
  .l-index--guide {
    padding-top: 40px;
    padding-bottom: 110px;
  }
}
.l-index--guide__info {
  max-width: 920px;
  margin: 53px auto 0;
}
@media screen and (min-width: 768px) {
  .l-index--guide__info {
    margin-top: 68px;
  }
}
.l-index--guide__info > section:not(:last-of-type) {
  margin-bottom: 53px;
}
@media screen and (min-width: 768px) {
  .l-index--guide__info > section:not(:last-of-type) {
    margin-bottom: 62px;
  }
}
.l-index--guide__info__price, .l-index--guide__info__time {
  font-size: 2.4rem;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .l-index--guide__info__price, .l-index--guide__info__time {
    font-size: 3.2rem;
  }
}
.l-index--guide__info__price span, .l-index--guide__info__time span {
  font-size: 1.8rem;
  margin-left: 12px;
}
@media screen and (min-width: 768px) {
  .l-index--guide__info__price span, .l-index--guide__info__time span {
    font-size: 2.4rem;
  }
}
.l-index--guide__info__price__time {
  display: inline-block;
  padding: 0 20px;
  margin-right: 12px;
  margin-left: 0 !important;
  font-size: 1.8rem;
  background: #000;
}
@media screen and (min-width: 768px) {
  .l-index--guide__info__price__time {
    font-size: 2rem;
  }
}
.l-index--slider {
  padding: 0 15px !important;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .l-index--slider {
    margin-top: 80px;
  }
}
.l-index--slider .swiper-slide {
  width: 75%;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .l-index--slider .swiper-slide {
    width: auto;
  }
}
.l-index--slider .swiper-slide:hover {
  opacity: 1;
}
.l-index--slider__control {
  height: 54px;
  margin: 40px 0 80px;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .l-index--slider__control {
    width: 100%;
    max-width: var(--container-width);
  }
}
@media screen and (min-width: 768px) {
  .l-index--slider__control {
    height: 65px;
    margin-bottom: 100px;
  }
}
.l-index--slider__control .swiper-button-prev, .l-index--slider__control .swiper-button-next {
  position: relative !important;
  display: block;
  margin: 0;
  width: 54px;
  height: 54px;
  top: 0 !important;
  opacity: 1 !important;
}
@media screen and (min-width: 768px) {
  .l-index--slider__control .swiper-button-prev, .l-index--slider__control .swiper-button-next {
    width: 65px;
    height: 65px;
  }
}
.l-index--slider__control .swiper-button-prev:after, .l-index--slider__control .swiper-button-next:after {
  content: "";
  width: 54px;
  height: 54px;
  display: block;
  background: url(../images/common/ico-arrow-slide.svg) no-repeat;
  background-size: contain;
}
@media screen and (min-width: 768px) {
  .l-index--slider__control .swiper-button-prev:after, .l-index--slider__control .swiper-button-next:after {
    width: 65px;
    height: 65px;
  }
}
.l-index--slider__control .swiper-button-prev:hover:after, .l-index--slider__control .swiper-button-next:hover:after {
  background-image: url(../images/common/ico-arrow-slide-bk.svg);
}
.l-index--slider__control .swiper-button-prev {
  margin-right: 26px;
}
@media screen and (min-width: 768px) {
  .l-index--slider__control .swiper-button-prev {
    margin-right: 30px;
  }
}
.l-index--slider__control .swiper-button-next {
  margin-left: 26px;
  transform: rotate(180deg);
}
@media screen and (min-width: 768px) {
  .l-index--slider__control .swiper-button-next {
    margin-left: 30px;
  }
}
.l-index--slider__control .swiper-pagination {
  display: none;
}
@media screen and (min-width: 768px) {
  .l-index--slider__control .swiper-pagination {
    display: block;
    width: 70% !important;
    height: 1px !important;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto !important;
    margin: auto;
    background: rgb(149, 149, 149);
  }
  .l-index--slider__control .swiper-pagination .swiper-pagination-progressbar-fill {
    background: rgb(8, 20, 29);
  }
}

.c-button {
  min-width: 250px;
  max-width: 500px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 100vh;
  color: #fff;
  text-decoration: none;
  padding: 20px 60px 20px 20px;
  position: relative;
  line-height: 1.1em;
  background: transparent;
  font-family: var(--serif-font-family);
  font-size: 1.6rem;
  font-weight: bold;
  overflow: hidden;
  z-index: 5;
}
@media screen and (min-width: 768px) {
  .c-button {
    min-width: 320px;
    padding-right: 83px;
  }
}
.c-button:after {
  content: "";
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/common/ico-arrow.svg) no-repeat;
  background-size: contain;
}
.c-button:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 100%;
  width: 700px;
  height: 700px;
  background: #08141D;
  border-radius: 50%;
  z-index: 0;
  transition: all 0.4s ease-out;
}
.c-button.black {
  color: #08141D;
  border-color: #08141D;
}
.c-button.black:after {
  background-image: url(../images/common/ico-arrow-b.svg);
}
.c-button:hover {
  opacity: 1;
  border: 1px solid #08141D;
  color: #fff;
}
.c-button:hover:after {
  background-image: url(../images/common/ico-arrow.svg);
}
.c-button:hover:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -5;
}
.c-button.center {
  text-align: center;
  padding: 20px;
}

.c-text--required {
  display: inline-block;
  font-size: 1.5rem;
  color: #fff;
  background: #D30101;
  font-weight: bold;
  padding: 0 5px;
  border-radius: 5px;
}
.c-text--optional {
  display: inline-block;
  font-size: 1.5rem;
  color: #fff;
  background: #707070;
  font-weight: bold;
  padding: 0 5px;
  border-radius: 5px;
}
.c-text--hr {
  border: none;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 40px;
}
.c-text--agreement {
  text-align: center;
  border: 1px solid #08141D;
}

.p-form--wrap {
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .p-form--wrap {
    padding-bottom: 80px;
  }
}
.p-form--radio label {
  display: block;
  margin-bottom: 20px;
}
.p-form--status {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 25px;
}
.p-form--status > li {
  flex-grow: 1;
  background: #E3E3E3;
  font-weight: bold;
  text-align: center;
  padding: 10px 15px;
  position: relative;
}
.p-form--status > li.is-active {
  background: #0C161F;
  color: #fff;
}
.p-form--status > li:not(:last-child):after {
  content: "";
  height: 29px;
  width: 10px;
  position: absolute;
  right: -17px;
  bottom: 0;
  top: 0;
  margin: auto;
  background: url(../images/common/ico-arrow-flow.svg) no-repeat;
  background-size: contain;
}
.p-form--title {
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: 1px solid #C7C7C7;
  padding-bottom: 20px;
  margin-top: 60px;
}
@media screen and (min-width: 768px) {
  .p-form--title {
    font-size: 2.4rem;
    margin-top: 90px;
  }
}
.p-form--wrap {
  padding: 0 15px;
  margin-bottom: 40px;
}
.p-form--label {
  font-weight: bold;
}
.p-form--textarea {
  width: 100%;
  min-height: 200px;
  resize: vertical;
  border: 1px solid #DDDDDD;
}
.p-form--textarea:placeholder-shown {
  background: #FDE8E8;
}
.p-form--text {
  width: 100%;
  max-width: 445px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #DDDDDD;
  display: block;
  margin-top: 7px;
}
.p-form--text:placeholder-shown {
  background: #FDE8E8;
}
.p-form--text.small {
  max-width: 345px;
}
.p-form--agreement {
  border: 1px solid #08141D;
  text-align: center;
}
.p-form--agreement__head {
  color: #fff;
  background: #09141D;
  font-size: 1.8rem;
  margin: 0;
  padding: 20px;
  text-align: center;
  font-weight: normal;
}
@media screen and (min-width: 768px) {
  .p-form--agreement__head {
    font-size: 2.4rem;
  }
}
.p-form--agreement__content {
  padding: 20px;
  text-align: left;
}
.p-form--agreement__check {
  text-align: center;
  margin: 20px 0;
}
.p-form--agreement__submit {
  text-align: center;
  margin: 40px 0 20px;
}

.p-heading {
  font-size: 2.4rem;
  margin-top: 60px;
}
@media screen and (min-width: 768px) {
  .p-heading {
    font-size: 2.8rem;
    margin-top: 80px;
  }
}
.p-heading--top {
  font-size: 3.2rem;
  margin: 0;
  font-weight: normal;
}
@media screen and (min-width: 768px) {
  .p-heading--top {
    font-size: 4.8rem;
  }
}
.p-heading--top > span {
  display: block;
  font-size: 1.4rem;
  color: #B1B1B1;
}
@media screen and (min-width: 768px) {
  .p-heading--top > span {
    font-size: 1.8rem;
  }
}
.p-heading--underline {
  font-size: 2.4rem;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 19px;
  font-weight: normal;
}
@media screen and (min-width: 768px) {
  .p-heading--underline {
    font-size: 2.8rem;
    padding-bottom: 10px;
    margin-bottom: 28px;
  }
}
.p-heading--em {
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .p-heading--em {
    font-size: 2.8rem;
  }
}
.p-heading--contact {
  font-weight: normal;
  text-align: center;
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) {
  .p-heading--contact {
    font-size: 3.2rem;
  }
}

.p-list {
  padding-left: 20px;
}
.p-list li {
  margin-bottom: 13px;
  position: relative;
}
.p-list li::marker {
  font-size: 0.5em;
}
.p-list a {
  color: #000;
}
.p-list--number li {
  list-style-type: none;
  counter-increment: cnt;
  position: relative;
  margin: 20px 0;
}
.p-list--number li::before {
  position: absolute;
  left: -2em;
  content: "(" counter(cnt) ") ";
}

.p-table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  margin: 60px 0 0;
}
.p-table tr:first-of-type td, .p-table tr:first-of-type th {
  border-top: 1px solid #C7C7C7;
}
.p-table td, .p-table th {
  border-bottom: 1px solid #C7C7C7;
  padding: 20px;
  text-align: left;
}
.p-table td:first-child, .p-table th:first-child {
  vertical-align: top;
  white-space: nowrap;
}
.p-table.end-right tr th:first-child, .p-table.end-right tr td:first-child {
  white-space: wrap;
}
.p-table.end-right tr th:last-child, .p-table.end-right tr td:last-child {
  text-align: right;
  white-space: nowrap;
}

.u-animation--slidein {
  transform: translate(0, 30px);
  opacity: 0;
  transition: 1s;
  transition-property: transform, opacity;
}
.u-animation--slidein.is-show {
  transform: translate(0);
  opacity: 1;
}

.u-mb-10 {
  margin-bottom: 10px;
}
.u-mb-60 {
  margin-bottom: 60px;
}
.u-mt-40 {
  margin-top: 40px;
}

.u-text--center {
  text-align: center;
}
.u-text--left {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .u-text--brsp {
    display: none;
  }
}
.u-text--18 {
  font-size: 1.8rem;
}
.u-text--14 {
  font-size: 1.4rem;
}
.u-text--24 {
  font-size: 2.4rem;
}