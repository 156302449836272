@use '../global/' as *;

.l-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  background: #fff;
  &__logo {
    margin: 0;
    padding: 0;
    position: relative;
    align-self: flex-start;
    width: 98px;
    height: 120px;
    > img {
      width: 98px;
      height: 120px;
      display: block;
      position: absolute;
    }
    a {
      &:hover {
        opacity: 1;
      }
    }
  }
  &__nav {
    align-self: center;
    margin-right: 12px;
    line-height: 1.2;
    display: none;
    @include responsive(md) {
      display: block;
    }
    > ul {
      display: flex;
      align-items: center;
      gap: 20px;
      list-style-type: none;
      li {
        position: relative;
        &:not([class]) {
          padding-right: 20px;
          &:after {
            content: "";
            width: 1px;
            height: 1.3em;
            background: #C7C7C7;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &:has(+ .l-header__nav__reserve) {
            padding-right: 0;
            &:after {
              display: none;
            }
          }
        }
        &.l-header__nav__reserve {
          > a {
            padding: 12px 17px;
            background: #09141D;
            color: #fff;
            border: 1px solid #09141D;
            transition: .2s;
            &:hover {
              background: #fff;
              color: #09141D;
              opacity: 1;
            }
          }
        }
      }
    }
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        opacity: .6;
      }
    }
  }
  &__toggle {
    display: block;
    border: none;
    background: transparent;
    margin-right: 17px;
    > img {
      width: 30px;
    }
    @include responsive(md) {
      display: none;
    }

    &__open {
    }
    &__close {
      display: none;
    }
    &.is-active {
      .l-header__toggle__open {
        display: none;
      }
      .l-header__toggle__close {
        display: block;
      }
    }
  }
  &__top {
    margin: 0;
    position: absolute;
    top: 0;
    left: -1px;
    z-index: 4010;
    img {
      width: 155px;
    }
    a {
      &:hover {
        opacity: 1;
      }
    }
  }
  &__navsp {
    overflow-y: scroll;
    position: fixed;
    height: calc(100% - 60px);
    width: 100%;
    z-index: 3990;
    background: url(../images/common/bg-menu.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 158px 15px 40px;
    color: #fff;
    display: none;
    // display: block;
    @include responsive(md) {
      display: none !important;
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        opacity: .6;
      }
    }
    &__list {
      list-style-type: none;
      margin: 0 0 24px;
      padding: 0;
      color: #fff;
      font-size: 2.4rem;
      > li:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }
    &__button {
      display: block;
      text-align: center;
      background: #09141D;
      padding: 20px;
      font-size: 2.0rem;
      margin-top: 30px;
    }
    &__links {
      margin-top: 40px;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
    }
  }
}
