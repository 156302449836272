@use '../global/' as *;

.l-index {
  &--loading {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #0B0B0B;
    z-index: 5000;
    animation: loading .5s both 2.5s ease-in;
    @keyframes loading {
      0% {
        opacity: 1;
      }
      99% {
        z-index: 5000;
      }
      100% {
        opacity: 0;
        z-index: -5000;
      }
    }
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: loadimg 1s both ease;
      @keyframes loadimg {
        from {
          filter: blur(10px);
          transform: translate(-50%, -30%);
          opacity: 0;
        }
        to {
          filter: blur(0);
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }
  &--top {
    width: 100%;
    height: calc(100vh - 60px);
    background: #333;
    overflow: hidden;
    position: relative;
    @include responsive(md) {
      display: flex;
    }
    &__cc {
      position: absolute;
      top: 50%;
      left: 18px;
      z-index: 10;
      transform: translateY(-50%);
      color: #fff;
      animation: blur 2s both 2s ease-in;
      letter-spacing: .2em;
      @keyframes blur {
        from {
          filter: blur(10px);
        }
        to {
          filter: blur(0);
        }
      }
      @include responsive(md) {
        top: auto;
        left: 140px;
        bottom: 70px;
        transform: translateY(0);
      }
      &__box {
        display: inline-block;
        font-size: 5vw;
        font-size: clamp(14px, 5vw, 24px);
        letter-spacing: 0.1em;
        padding: 12px;
        border: 1px solid #fff;
        margin-bottom: 17px;
        @include responsive(md) {
          font-size: 2.8rem;
        }
      }
      &__text {
        margin: 0;
        font-size: 3.2rem;
        font-weight: normal;
        letter-spacing: 0.12em;
        @include responsive(md) {
          font-size: 4.8rem;
        }
      }
    }
    &__instagram {
      position: absolute;
      right: 0;
      bottom: 35px;
      z-index: 5;
      @include responsive(md) {
        right: 35px;
      }
      > img {
        display: block;
        height: 119px;
        width: 119px;
        @include responsive(md) {
          height: 142px;
          width: 143px;
        }
      }
    }
    &__image {
      width: 100%;
      height: 50%;
      position: relative;
      z-index: 5;
      @include responsive(md) {
        width: 50%;
        height: 100%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(0);
        animation :slideshow 20s ease infinite;
      }
      picture {
        &:nth-of-type(2) img {
          animation-delay: 10s;
          opacity: 0;
        }
      }
      &.right {
        img {
          animation-delay: .1s;
        }
        picture {
          &:nth-of-type(2) img {
            animation-delay: 10.1s;
          }
        }
      }
      @keyframes slideshow{
        0%{
          transform: translateY(100%);
          opacity: 1;
          z-index: 1;
        }
        3%{
          transform: translateY(0);
        }
        50%{
          opacity: 1;
        }
        51% {
          z-index: 0;
          opacity: 0;
        }
        100%{
          opacity: 0;
        }
      }

    }
    &__bottom {
      text-align: center;
      padding: 45px 15px;
      @include responsive(md) {
        padding: 88px 15px 95px;
      }
      > h2 {
        margin: 0;
        font-size: 1.6rem;
        color: #6A6A6A;
        @include responsive(md) {
          font-size: 2.0rem;
        }
      }
    }
    &__scroll {
      position: absolute;
      left: 10px;
      bottom: 20px;
      z-index: 10;
      color: #fff;
      transform: rotate(90deg);
      &::after{
        content: "";
          /*描画位置*/
        position: absolute;
        top: -10px;
        right: 50px;
        display: block;
          /*線の形状*/
        height: 1px;
        width: 40px;
        transform: rotate(180deg);
        background: #fff;
          /*線の動き1.4秒かけて動く。永遠にループ*/
        animation: pathmove 1.4s ease-in-out infinite;
        opacity:0;
      }
      @keyframes pathmove{
        0%{
          width:0;
          left: -50px;
          opacity: 0;
        }
        30%{
          width:60px;
          opacity: 1;
        }
        100%{
          width:0;
          left:50px;
          opacity: 0;
        }
      }
    }
  }
  &--concept {
    background: url(../images/index/bg_g.jpg) no-repeat #444;
    background-size: cover;
    background-position: center;
    color: #fff;
    p {
      line-height: 1.925;
    }
    &.black {
      background: #0B0B0B;
      .l-index--concept__content {
        @include responsive(md) {
          justify-content: flex-start;
        }
      }
      .l-index--concept__word {
        order: 2;
        flex-grow: 1;
        letter-spacing: 0.04em;
        position: relative;
        &:after {
          content: "";
          width: 94px;
          height: 228px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(../images/index/bg_logo.png) no-repeat;
          background-size: contain;
          @include responsive(md) {
            width: 152px;
            height: 370px;
          }
        }
      }
      .l-index--concept__image {
        order: 1;
      }
    }
    &__content {
      padding: 90px 0 80px;
      @include container;
      display: block;
      @include responsive(md) {
        display: flex;
        justify-content: space-between;
      }
    }
    &__word {
      margin-bottom: 47px;
      @include responsive(md) {
        margin-left: 95px;
      }
      > p {
        @include responsive(md) {
          margin-bottom: 48px;
        }
      }
      > img {
        display: block;
        width: 100px;
        margin: 0 auto 60px;
        @include responsive(md) {
          width: 159px;
          margin-bottom: 72px;
        }
      }
    }
  }
  &--facility {
    margin-top: 40px;
    @include responsive(md) {
      margin-top: 100px;
    }
    &__info {
      @include container;
      @include responsive(md) {
        display: flex;
        > * {
          width: 50%;
        }
      }
      &__word {
        margin-bottom: 40px;
      }
    }
  }

  &--guide {
    background: url(../images/index/bg_wg.jpg) no-repeat #444;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding-top: 100px;
    padding-bottom: 112px;
    @include responsive(md) {
      padding-top: 40px;
      padding-bottom: 110px;
    }
    &__info {
      max-width: 920px;
      margin: 53px auto 0;
      @include responsive(md) {
        margin-top: 68px;
      }
      > section {
        &:not(:last-of-type) {
          margin-bottom: 53px;
          @include responsive(md) {
            margin-bottom: 62px;
          }
        }
      }
      &__price, &__time {
        font-size: 2.4rem;
        margin: 0;
        @include responsive(md) {
          font-size: 3.2rem;
        }
        span {
          font-size: 1.8rem;
          margin-left: 12px;
          @include responsive(md) {
            font-size: 2.4rem;
          }
        }
      }
      &__price {
        &__time {
          display: inline-block;
          padding: 0 20px;
          margin-right: 12px;
          margin-left: 0 !important;
          font-size: 1.8rem;
          background: #000;
          @include responsive(md) {
            font-size: 2.0rem;
          }
        }
      }
    }
  }
  &--slider {
    padding: 0 15px !important;
    margin-top: 40px;
    @include responsive(md) {
      margin-top: 80px;
    }
    .swiper-slide {
      width: 75%;
      text-decoration: none;
      @include responsive(md) {
        width: auto;
      }
      &:hover {
        opacity: 1;
      }
    }
    &__control {
      height: 54px;
      margin: 40px 0 80px;
      @include container;
      display: flex;
      position: relative;
      align-items: center;
      padding: 0;
      @include responsive(md) {
        height: 65px;
        margin-bottom: 100px;
      }
      .swiper-button-prev, .swiper-button-next {
        position: relative !important;
        display: block;
        margin: 0;
        width: 54px;
        height: 54px;
        top: 0 !important;
        opacity: 1 !important;
        @include responsive(md) {
          width: 65px;
          height: 65px;
        }
        &:after {
          content: "";
          width: 54px;
          height: 54px;
          display: block;
          background: url(../images/common/ico-arrow-slide.svg) no-repeat;
          background-size: contain;
          @include responsive(md) {
            width: 65px;
            height: 65px;
          }
        }
        &:hover {
          &:after {
            background-image: url(../images/common/ico-arrow-slide-bk.svg);
          }
        }
      }
      .swiper-button-prev {
        margin-right: 26px;
        @include responsive(md) {
          margin-right: 30px;
        }
      }
      .swiper-button-next {
        margin-left: 26px;
        transform: rotate(180deg);
        @include responsive(md) {
          margin-left: 30px;
        }
      }
      .swiper-pagination {
        display: none;
        @include responsive(md) {
          display: block;
          width: 70% !important;
          height: 1px !important;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          left: auto !important;
          margin: auto;
          background: rgba(149,149,149,1);
          .swiper-pagination-progressbar-fill {
            background: rgba(8,20,29,1);
          }
        }
      }
    }
  }
}
