@use '../global/' as *;

.l-about {
  &--top {
    background: #0B0B0B;
    color: #fff;
    position: relative;
    padding: 10px 0;
    &:before {
      content: "";
      height: 60px;
      width: 100%;
      display: block;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }
    > * {
      z-index: 10;
    }
    &__img01 {
      position: relative;
      display: block;
      max-width: 545px;
      width: 77%;
      margin-bottom: 60px;
      @include responsive(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 35%;
      }
    }
    &__img02 {
      position: relative;
      display: block;
      max-width: 235px;
      width: 77%;
      margin: 68px 0 0 auto;
      @include responsive(md) {
        position: absolute;
        top: 25%;
        right: 0;
        width: 15%;
      }
    }
    &__concept {
      position: relative;
      @include container;
      background: url(../images/index/bg_logo.png) no-repeat;
      background-position: left 20px center;
      background-size: auto 80%;
      @include responsive(md) {
        max-width: 540px;
        width: 50%;
        margin-top: 170px;
        padding-left: 75px;
        margin-left: 40%;
        margin-bottom: 180px;
        z-index: 15;
      }
      &__title {
        font-size: 2.4rem;
        font-weight: normal;
        margin-bottom: 44px;
        @include responsive(md) {
          font-size: 2.8rem;
          margin-bottom: 60px;
        }
      }
    }
    &__guide {
      position: relative;
      margin-top: -40px;
      padding-top: 100px;
      padding-bottom: 60px;
      @include responsive(md) {
        margin-top: -140px;
        padding-top: 200px;
      }
      &__inner {
        @include container;
      }
      &__content {
        @include responsive(md) {
          width: 40%;
        }
      }
      &__map {
        display: block;
        width: 100%;
        max-width: 500px;
        margin: 40px auto;
        @include responsive(md) {
          position: absolute;
          right: 1%;
          top: 200px;
          margin: auto 0;
          width: 50%;
          max-width: 680px;
        }
      }
      &__list {
        padding-left: 20px;
        li {
          &::marker {
            font-size: .5em;
          }
          a {
            color: #fff;
            position: relative;
            &:hover {
              opacity: .6;
            }
            &:after {
              content: "";
              display: inline-block;
              position: relative;
              height: 10px;
              width: 10px;
              border: 1px solid #fff;
              border-right: none;
              border-top: none;
              margin-left: 10px;
              transform: rotate(-45deg);
              top: -0.2em;
            }
          }
        }
      }
    }
  }
  &--content {
    &__head {
      position: relative;
      padding-bottom: 70px;
      @include responsive(md) {
        padding-bottom: 54px;
      }
      &__img {
        width: 100%;
        z-index: 5;
      }
      &__word {
        @include container;
        position: relative;
        margin: 0;
        bottom: -70px;
        @include responsive(md) {
          bottom: -54px;
        }
        > span {
          background: #08141D;
          font-size: 2.4rem;
          color: #fff;
          font-weight: normal;
          display: inline-block;
          position: absolute;
          z-index: 10;
          bottom: 0;
          left: 0;
          padding: 20px 20px 30px 15px;
          letter-spacing: 0.2em;
          @include responsive(md) {
            font-size: 3.2rem;
            padding: 16px 77px 30px 77px;
          }
        }
      }
    }
    &__content {
      @include container;
      @include responsive(md) {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        margin: 60px auto;
      }
      &.pc-r {
        @include responsive(md) {
          .l-about--content__content__word {
            order: 2;
          }
          .l-about--content__content__img {
            order: 1;
          }
        }
      }
      &__word {
        margin: 40px 0;
        @include responsive(md) {
          width: 40%;
        }
      }
      &__img {
        margin: 40px 0;
        @include responsive(md) {
          width: 48%;
        }
      }
      &__caption {
        font-size: 1.4rem;
      }
    }
  }
}
