@use '../../global/' as *;

.c-text {
  &--required {
    display: inline-block;
    font-size: 1.5rem;
    color: #fff;
    background: #D30101;
    font-weight: bold;
    padding: 0 5px;
    border-radius: 5px;
  }
  &--optional {
    display: inline-block;
    font-size: 1.5rem;
    color: #fff;
    background: #707070;
    font-weight: bold;
    padding: 0 5px;
    border-radius: 5px;
  }

  &--hr {
    border: none;
    border-bottom: 1px solid #C7C7C7;
    margin-bottom: 40px;
  }

  &--agreement {
    text-align: center;
    border: 1px solid #08141D;
  }
}
