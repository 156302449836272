@use '../../global/' as *;

.c-button{
  min-width: 250px;
  max-width: 500px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 100vh;
  color: #fff;
  text-decoration: none;
  padding: 20px 60px 20px 20px;
  position: relative;
  line-height: 1.1em;
  background: transparent;
  font-family: var(--serif-font-family);
  font-size: 1.6rem;
  font-weight: bold;
  overflow: hidden;
  z-index: 5;
  @include responsive(md) {
    min-width: 320px;
    padding-right: 83px;
  }
  &:after {
    content: "";
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../images/common/ico-arrow.svg) no-repeat;
    background-size: contain;
  }
  &:before {
    content:"";
    position: absolute;
    top:100%;
    left: 100%;
    width: 700px;
    height: 700px;
    background: #08141D;
    border-radius: 50%;
    z-index: 0;
    transition: all .4s ease-out;
  }
  &.black {
    color: #08141D;
    border-color: #08141D;
    &:after {
      background-image: url(../images/common/ico-arrow-b.svg);
    }
  }
  &:hover {
    opacity: 1;
    border: 1px solid #08141D;
    color: #fff;
    &:after {
      background-image: url(../images/common/ico-arrow.svg);
    }
    &:before {
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: -5;
    }
  }
  &.center {
    text-align: center;
    padding: 20px;
  }
}
