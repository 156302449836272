@use '../global/' as *;

.l-footer {
  &__reserve {
    padding: 20px 15px;
    background: url(../images/index/bg_g_02.jpg) no-repeat #666;
    background-size: cover;
    background-position: center;
    position: relative;
    @include responsive(md) {
      padding: 60px;
    }
    &:after {
      content: "";
      width: 100%;
      height: 40%;
      background: #262626;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 5;
    }
    &__inner {
      position: relative;
      background: #fff;
      padding: 60px;
      z-index: 10;
      text-align: center;
      letter-spacing: 0.04em;
    }
    &__title {
      font-size: 2.4rem;
      font-weight: normal;
      @include responsive(md) {
        font-size: 3.2rem;
      }
    }
    &__tel {
      font-size: 2.8rem;
      font-weight: bold;
      @include responsive(md) {
        font-size: 4.9rem;
      }
      > img {
        width: .8em;
        height: auto;
        margin-right: .2em;
      }
    }
  }
  &__content {
    background: #262626;
    padding: 20px 0 7px;
    @include responsive(md) {
      padding: 40px 0 45px;
    }
    &__top {
      border-bottom: 1px solid #C7C7C7;
      padding: 0 15px 40px;
      @include responsive(md) {
        padding-bottom: 80px;
      }
      &__sns, &__list {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        justify-content: center;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
      &__sns {
        margin-bottom: 25px;
        gap: 30px;
      }
      &__list {
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        @include responsive(md) {
          flex-direction: row;
          gap: 30px;
        }
      }
    }
    &__bottom {
      padding: 25px 15px 0;
      @include responsive(md) {
        padding-top: 40px;
      }
      &__list {
        flex-wrap: wrap;
        gap: 16px;
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        justify-content: center;
        font-size: 1.4rem;
        a {
          color: #fff;
          text-decoration: none;
        }
        @include responsive(md) {
          justify-content: flex-end;
          gap: 30px;
        }
      }
      &__bottom {
        margin-top: 40px;
        @include responsive(md) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      &__logo {
        text-align: center;
        color: #fff;
        font-size: 1.8rem;
        img {
          margin-right: 12px;
        }
        @include responsive(md) {
          display: flex;
          align-items: center;
        }
        &__link {
          color: #fff;
          text-decoration: none;
          font-size: 1.2rem;
          border-bottom: 1px solid #fff;
          padding-bottom: 2px;
          @include responsive(md) {
            margin-left: 20px;
          }
          > img {
            height: 9px;
            width: 9px;
            margin: 0 0 0 5.5px;
          }
        }
      }
      &__small {
        display: block;
        font-size: 1.2rem;
        text-align: right;
        margin-top: 26px;
        color: #fff;
        @include responsive(md) {
          margin-top: 0;
        }
      }
    }
  }
}
