@use '../global/' as *;

.l-guide {
  &--time {
    padding: 60px 0;
    @include container;
    color: #fff;
    margin-top: 60px;
    @include responsive(md) {
      padding: 93px 15px 100px;
      margin-top: 80px;
      max-width: 920px;
    }
    > * {
      text-align: center;
    }
    > section {
      margin-bottom: 60px;
    }
    &__title {
      font-size: 2.4rem;
      letter-spacing: 0.2em;
      margin: 0 auto 50px;
      font-weight: normal;
      @include responsive(md) {
        font-size: 3.2rem;
        letter-spacing: 0.04em;
      }
      &.small {
        @include responsive(md) {
          font-size: 2.4rem;
        }
      }
    }
    &__info {
      text-align: center;
      &__title {
        background: #09141D;
        font-size: 1.8rem;
        padding: 25px;
        @include responsive(md) {
          font-size: 2.4rem;
        }
      }
      &__content {
        background: #fff;
        color: #09141D;
        font-size: 3.2rem;
        padding: 25px;
        @include responsive(md) {
          font-size: 3.8rem;
        }
        span {
          font-size: 2.2rem;
          @include responsive(md) {
            font-size: 2.4rem;
          }
        }
        &__inner {
          display: inline-block;
          text-align: left;
        }
        &__small {
          display: block;
          font-size: 1.8rem;
          @include responsive(md) {
            font-size: 2.0rem;
          }
        }
        &__small2 {
          font-size: 2.0rem;
          @include responsive(md) {
            font-size: 2.8rem;
          }
        }
        p {
          font-size: var(--base-font-size);
          text-align: right;
        }
      }
    }
  }
  &--access {
    color: #fff;
    padding: 60px 0 40px;
    @include container;
    @include responsive(md) {
      min-height: 548px;
      max-width: 920px;
    }
    &__wrap {
      @include responsive(md) {
        max-width: 445px;
        width: 40%;
      }
    }
    &__title {
      font-size: 2.4rem;
      @include responsive(md) {
        font-size: 3.2rem;
      }
    }
    &--map {
      width: 100%;
      height: 321px;
      filter:grayscale(100%);
      -webkit-filter:grayscale(100%);
      @include responsive(md) {
        position: absolute;
        height: 100%;
        width: 57%;
        max-width: 640px;
        right: 0;
        top: 0;
      }
    }
  }
  &--flow {
    padding: 60px 0 50px;
    @include container;
    @include responsive(md) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    &__inner {
      margin: 32px auto 0;
      @include responsive(md) {
        max-width: 920px;
        margin-top: 60px;
      }
    }
    &__content {
      margin-bottom: 48px;
      @include responsive(md) {
        display: flex;
        margin-bottom: 60px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &__head {
        text-align: center;
        background: #09141D;
        color: #fff;
        font-size: 2.0rem;
        padding: 40px;
        @include responsive(md) {
          width: 350px;
          flex-shrink: 0;
          font-size: 2.4rem;
          margin-right: 30px;
        }
        > * {
          display: block;
          margin: 0 auto;
        }
        > img {
          margin-bottom: 20px;
        }
        > span {
          font-size: 1.6rem;
          @include responsive(md) {
            font-size: 1.8rem;
          }
        }
      }
      &__content {
        > p {
          line-height: 2em;
          margin: 0;
        }
      }
    }
  }
}
