@use '../../global/' as *;

.u-text {
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }

  &--brsp {
    @include responsive(md) {
      display: none;
    }
  }

  &--18 {
    font-size: 1.8rem;
  }
  &--14 {
    font-size: 1.4rem;
  }
  &--24 {
    font-size: 2.4rem;
  }
}
