@use '../../global/' as *;

.p-heading{
  font-size: 2.4rem;
  margin-top: 60px;
  @include responsive(md) {
    font-size: 2.8rem;
    margin-top: 80px;
  }
  &--top {
    font-size: 3.2rem;
    margin: 0;
    font-weight: normal;
    @include responsive(md) {
      font-size: 4.8rem;
    }
    > span {
      display: block;
      font-size: 1.4rem;
      color: #B1B1B1;
      @include responsive(md) {
        font-size: 1.8rem;
      }
    }
  }
  &--underline {
    font-size: 2.4rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 19px;
    font-weight: normal;
    @include responsive(md) {
      font-size: 2.8rem;
      padding-bottom: 10px;
      margin-bottom: 28px;
    }
  }
  &--em {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 0;
    @include responsive(md) {
      font-size: 2.8rem;
    }
  }

  &--contact {
    font-weight: normal;
    text-align: center;
    font-size: 2.4rem;
    @include responsive(md) {
      font-size: 3.2rem;
    }
  }
}
