@use '../../global/' as *;

.p-list {
  padding-left: 20px;
  li {
    margin-bottom: 13px;
    position: relative;
    &::marker {
      font-size: .5em;
    }
  }
  a {
    color: #000;
  }
  &--number {
    li {
      list-style-type: none;
      counter-increment: cnt;
      position: relative;
      margin: 20px 0;
      &::before {
        position: absolute;
        left: -2em;
        content: "(" counter(cnt) ") ";
      }
    }
  }
}
