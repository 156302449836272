//---------------------------------------------------------------------------------------------
//ブレークポイント指定基本min-width
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$breakpoints: (
  "xs": (
    min-width: 0,
  ),
  "sm": (
    min-width: $sm,
  ),
  "md": (
    min-width: $md,
  ),
  "lg": (
    min-width: $lg,
  ),
  "xl": (
    min-width: $xl,
  ),
  "xxl": (
    min-width: $xxl,
  ),
) !default;
@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
}
/*
実装例
@include responsive(md) {
  font-size: 14px;
}
*/

//　背景mixin
// ----------------------------------------------------------
@mixin icon($width,$height,$path){
  &::after{
    content: '';
    width: $width;
    height: $height;
    display: inline-block;
    background-color: currentColor;
    mask: url("../images/common/#{$path}");
    @content;
  }
}
/*
実装例
@include icon(30px,30px,mainbg.jpg)
*/

//hover
// ----------------------------------------------------------
@mixin hover($focus: null, $opacity: null) {
  transition: var(--animation-normal);
  @if $focus == none {
    // focusが不要な場合
    @if $opacity == none {
      // focusと不透明度が不要な場合
      &:hover,
      &:active {
        opacity: $hover_opacity;
        @content;
      }
    } @else {
      // focus のみ不要な場合
      &:hover,
      &:active {
        opacity: $hover_opacity;
        @content;
      }
    }
  } @else if $opacity == none {
    // 不透明度のみ不要な場合
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      opacity: $hover_opacity;
      @content;
    }
  }
}
/*
実装例
@include hover{
  内容
}
*/

//containerサイズ
// ----------------------------------------------------------
@mixin container {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  @include responsive(md) {
    width: 100%;
    max-width: var(--container-width);
  }
}
